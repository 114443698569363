/**
 * Split tests to use in the app
 * Can be passed as a string (name) or an object with configuration
 * See defaults in split-test duck for default variants and other available options
 * @type {String|Array.Object}
 */

module.exports = [
  'CartUndoWithDelay', // Test 386
  'PickAndMixMicroTrailersV2', // Test 394
  'ProductMobileAboutCollapse', // Test 396
  'HomePageTrustBannerRemoveWaysToPay', // Test 398
  'UnifiedAddToCartButton', // Test 400
  'CategoryPageFourColumns', // Test 402
  'MegaMenuCategoriesTabV2', // Test 403
];
