import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Collapse from 'reactstrap/lib/Collapse';
import NavLink from 'reactstrap/lib/NavLink';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

import env from '../../../env';
import { countryCode } from '../../../Helpers/Geo/data';
import NavCartButton from '../../../containers/Cart/NavCartButton';
import Logout from '../../../containers/Auth/Logout';
import LoginBtn from '../../NavBar/LoginBtn';
import StarDealButton from '../../../containers/Product/StarDealButton';
import WishlistNavButton from '../../../containers/Wishlist/WishlistNavButton';
import { supportUrl } from '../../../config/lang';
import { isBulker } from '../../../Helpers/User/Traits';
import { switchSiteTheme } from '../../../redux/ducks/site-theme';
import store from '../../../redux/store';
import Price from '../../../containers/Utility/Price/Price';
import pricing from '../../../containers/StaticSearch/games-under-pricing';

const MobileSideBar = ({
  authenticated, email, siteBrand, siteTheme, pathname, liveDisplayTypes,
}) => {
  const [open, setOpen] = useState(false);
  const [openDiscover, setOpenDiscover] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [openBundle, setOpenBundle] = useState(false);
  const [openGameGenres, setOpenGameGenres] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);

  const isLight = siteTheme === 'light';

  useEffect(() => {
    if (pathname === '/new-password') {
      setOpen(false);
    }
  }, [pathname]);


  const handleClick = () => {
    setOpenDiscover(false);
    setOpenAccount(false);
    setOpenBundle(false);
    setOpenGameGenres(false);
    setOpenCategories(false);

    document.getElementById('navbar-side').scrollTop = 0;
    setOpen(!open);
  };

  const showProductLibrary = !isBulker();
  const liveDisplayTypesArray = Object.keys(liveDisplayTypes);
  const liveBooks = liveDisplayTypesArray.includes('book-bundle') || liveDisplayTypesArray.includes('comic-bundle');
  const liveSoftware = liveDisplayTypesArray.includes('software-bundle') || liveDisplayTypesArray.includes('elearning-bundle') || liveDisplayTypesArray.includes('elearning') || liveDisplayTypesArray.includes('audio-bundle') || liveDisplayTypesArray.includes('video-bundle');

  const TrackingNavLink = ({ to, children, onClick, ...props }) => {
    const onClickFunc = () => {
      store.dispatch({
        type: 'frontend/click/CLICK_INTERACTION',
        payload: { section: 'MobileSiderBar', value: to },
      });

      onClick();
    };

    return (
      <NavLink to={to} onClick={onClickFunc} {...props}>
        {children}
      </NavLink>
    );
  };

  return (
    <div className="d-flex MobileSiderBar">
      <button
        aria-label="Close Side Bar"
        className={classNames({ 'd-block': open }, 'site-overlay w-100')}
        onClick={() => handleClick()}
      />
      <NavLink
        className="text-right mobile-nav-button d-flex align-items-center"
        onClick={() => handleClick()}
      >
        <FontAwesomeIcon icon="bars" size="lg" />
      </NavLink>
      <div className="mobile-nav" id="navbar-side">
        {open &&
          <div className={classNames('side-bar', {
            'side-bar-reveal': open,
          })}
          >
            <div className="navbar-side-item first-item p-0 px-2">
              <span className="mobile-cart-items">
                <NavCartButton parentClick={() => handleClick()} />
              </span>
              <span className="mobile-cart-items">
                <WishlistNavButton parentClick={() => handleClick()} />
              </span>
              <NavLink className="text-right align-self-center" onClick={() => handleClick()}>
                <FontAwesomeIcon icon="times" size="lg" title="Close side bar" />
              </NavLink>
            </div>

            {!authenticated &&
              <div className="navbar-side-item mobile-login-container">
                <LoginBtn className="nav-link" />
              </div>
            }
            {authenticated && email &&
              <div className="navbar-side-item collapse-links">
                <div className="nav-link logged-in-as">
                  <FormattedMessage
                    id="mobilesidebar.logged-in"
                    defaultMessage="Logged in as {emailAddress}"
                    values={{
                      emailAddress: <span><br />{email}<br /></span>,
                    }}
                  />
                </div>
              </div>
            }
            {authenticated &&
              <NavLink className="navbar-side-item side-dropdown" onClick={() => setOpenAccount(!openAccount)}>
                <FormattedMessage id="mobilesidebar.account" defaultMessage="My Account" />
                {openAccount ?
                  <FontAwesomeIcon icon="chevron-down" />
                  :
                  <FontAwesomeIcon icon="chevron-right" />
                }
              </NavLink>
            }
            {openAccount &&
              <div className="collapse-links">
                <Collapse isOpen={openAccount}>
                  <NavLink tag={Link} to="/account" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FontAwesomeIcon icon="user" className="mr-2" />
                    <FormattedMessage id="mobilesidebar.acountoverview" defaultMessage="Account Overview" />
                  </NavLink>
                  <NavLink tag={Link} to="/orders" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FontAwesomeIcon icon="key" className="mr-2" />
                    <FormattedMessage id="mobilesidebar.order-history" defaultMessage="Order History & Keys" />
                  </NavLink>
                  {showProductLibrary &&
                    <NavLink tag={Link} to="/product-library" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                      <FontAwesomeIcon icon="book-open" className="mr-2" />
                      <FormattedMessage id="mobilesidebar.productlibrary" defaultMessage="Product Library" />
                    </NavLink>
                  }

                  <NavLink
                    className="navbar-side-item collapse-links"
                    onClick={() => switchSiteTheme(isLight ? 'dark' : 'light', 'mobile-side-bar', 'button')}
                  >
                    <FontAwesomeIcon icon="lightbulb" className="mr-2" />
                    {!isLight &&
                      <FormattedMessage id="mobilesidebar.lighttheme" defaultMessage="Light Theme" />
                    }
                    {isLight &&
                      <FormattedMessage id="mobilesidebar.darktheme" defaultMessage="Dark Theme" />
                    }
                  </NavLink>

                  <NavLink tag={Link} to="/wishlist" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FontAwesomeIcon icon="heart" className="mr-2" />
                    <FormattedMessage id="mobilesidebar.wishlist" defaultMessage="My Wishlist" />
                  </NavLink>

                  {!isBulker() &&
                    <NavLink tag={Link} to="/reviews" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                      <FontAwesomeIcon icon="star" className="mr-2" />
                      <FormattedMessage id="mobilesidebar.myreviews" defaultMessage="My Reviews" />
                    </NavLink>
                  }

                  <Logout tag={NavLink} className="navbar-side-item collapse-links nav-link" parentClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.logged-out" defaultMessage="Sign Out" />
                  </Logout>
                </Collapse>
              </div>
            }

            <NavLink className="navbar-side-item side-dropdown" onClick={() => setOpenDiscover(!openDiscover)}>
              <FormattedMessage id="mobilesidebar.discover" defaultMessage="Discover" />
              {openDiscover ?
                <FontAwesomeIcon icon="chevron-down" />
                :
                <FontAwesomeIcon icon="chevron-right" />
              }
            </NavLink>

            {openDiscover &&
              <div className="collapse-links">
                <Collapse isOpen={openDiscover}>
                  <TrackingNavLink tag={Link} to="/on-sale" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.allgamedeals" defaultMessage="All Game Deals" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/top-sellers" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.collapse.top-sellers" defaultMessage="Top Sellers" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/latest-deals" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.latestdeals" defaultMessage="Latest Deals" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/trending-deals" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.populargames" defaultMessage="Popular Games" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/ending-soon" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.lastchancetosave" defaultMessage="Last Chance to Save" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/best-games-of-2024" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.browse-genre" defaultMessage="Best Games of 2024" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/free-to-play" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.freetoplay" defaultMessage="Free to Play" />
                  </TrackingNavLink>
                </Collapse>
              </div>
            }


            <NavLink className="navbar-side-item side-dropdown" onClick={() => setOpenGameGenres(!openGameGenres)}>
              <FormattedMessage id="mobilesidebar.gamegenres" defaultMessage="Games Genres" />
              {openBundle ?
                <FontAwesomeIcon icon="chevron-down" />
                :
                <FontAwesomeIcon icon="chevron-right" />
              }
            </NavLink>

            {openGameGenres &&
              <div className="collapse-links">
                <Collapse isOpen={openGameGenres}>
                  <TrackingNavLink tag={Link} to="/categories/action" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.action" defaultMessage="Action" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/categories/adventure" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.adventure" defaultMessage="Adventure" />
                  </TrackingNavLink>


                  <TrackingNavLink tag={Link} to="/categories/rpg" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.roleplaying" defaultMessage="Role Playing" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/categories/simulation" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.simulation" defaultMessage="Simulation" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/categories/strategy" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.strategy" defaultMessage="Strategy" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/categories/sports-and-racing" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.sportsandracing" defaultMessage="Sports and Racing" />
                  </TrackingNavLink>
                </Collapse>
              </div>
            }

            <NavLink className="navbar-side-item side-dropdown" onClick={() => setOpenCategories(!openCategories)}>
              <FormattedMessage id="mobilesidebar.categories" defaultMessage="Categories" />
              {openCategories ?
                <FontAwesomeIcon icon="chevron-down" />
                :
                <FontAwesomeIcon icon="chevron-right" />
              }
            </NavLink>

            {openCategories &&
              <div className="collapse-links">
                <Collapse isOpen={openCategories}>
                  <TrackingNavLink tag={Link} to="/games-under/5" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage
                      id="mobilesidebar.link.under-five"
                      defaultMessage="Games Under {price}"
                      tagName="span"
                      values={{
                        price: <Price
                          hideDecimals
                          price={pricing['5'].max}
                        />,
                      }}
                    />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/franchises" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.browsebyfranchise" defaultMessage="Browse By Franchise" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/publishers" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.browsebypublisher" defaultMessage="Browse By Publisher" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/vr-games" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.vrgames" defaultMessage="VR Games" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/steam-deck-games" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.steamdeckgames" defaultMessage="Steam Deck Games" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/search?types=software%2Csoftware-bundle" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.software" defaultMessage="Software" />
                  </TrackingNavLink>

                  <TrackingNavLink tag={Link} to="/games-like" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.gameslike" defaultMessage="Games Like ..." />
                  </TrackingNavLink>

                  {env.giftCardCountries.includes(countryCode) &&
                    <TrackingNavLink tag={Link} to="/gift-cards" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                      <FormattedMessage id="mobilesidebar.giftcards" defaultMessage="Gift Cards" />
                    </TrackingNavLink>
                  }

                  <TrackingNavLink tag={Link} to="/search" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.browseall" defaultMessage="Browse All" />
                  </TrackingNavLink>
                </Collapse>
              </div>
            }

            <NavLink className="navbar-side-item side-dropdown" onClick={() => setOpenBundle(!openBundle)}>
              <FormattedMessage id="mobilesidebar.bundles" defaultMessage="Bundles" />
              {openBundle ?
                <FontAwesomeIcon icon="chevron-down" />
                :
                <FontAwesomeIcon icon="chevron-right" />
              }
            </NavLink>

            {openBundle &&
              <div className="collapse-links">
                <Collapse isOpen={openBundle}>

                  {liveDisplayTypesArray.includes('bundle') &&
                    <TrackingNavLink tag={Link} to="/bundle/games" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                      <FormattedMessage id="mobilesidebar.gamebundles" defaultMessage="Game Bundles" />
                    </TrackingNavLink>
                  }

                  {liveBooks &&
                    <TrackingNavLink tag={Link} to="/bundle/books" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                      <FormattedMessage id="mobilesidebar.bookbundle" defaultMessage="Book Bundles" />
                    </TrackingNavLink>
                  }

                  {liveSoftware &&
                    <TrackingNavLink tag={Link} to="/bundle/software" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                      <FormattedMessage id="mobilesidebar.softwarebundles" defaultMessage="Software Bundles" />
                    </TrackingNavLink>
                  }

                  <TrackingNavLink tag={Link} to="/bundle" className="navbar-side-item collapse-links" onClick={() => handleClick()}>
                    <FormattedMessage id="mobilesidebar.allbundles" defaultMessage="All Bundles" />
                  </TrackingNavLink>
                </Collapse>
              </div>
            }

            <TrackingNavLink tag={Link} to="/upcoming-games" className="navbar-side-item" onClick={() => handleClick()}>
              <FormattedMessage id="mobilesidebar.comingsoon" defaultMessage="Upcoming Games" />
            </TrackingNavLink>

            <TrackingNavLink tag={Link} to="/new-releases" className="navbar-side-item" onClick={() => handleClick()}>
              <FormattedMessage id="mobilesidebar.newreleases" defaultMessage="New releases" />
            </TrackingNavLink>

            {!['de'].includes(window.locale) &&
              <TrackingNavLink tag={Link} to="/mystery" className="navbar-side-item" onClick={() => handleClick()}>
                <FormattedMessage id="mobilesidebar.mystery" defaultMessage="Mystery" />
              </TrackingNavLink>
            }

            <TrackingNavLink tag={Link} to="/fantasyverse" className="navbar-side-item" onClick={() => handleClick()}>
              FantasyVerse
            </TrackingNavLink>

            <StarDealButton className="navbar-side-item nav-link" parentClick={() => handleClick()} />

            {siteBrand === 'hpomen' &&
              <TrackingNavLink tag={Link} to="/redeem-code" className="navbar-side-item" onClick={() => handleClick()}>
                <FormattedMessage id="mobilesidebar.redeemcode" defaultMessage="Redeem" />
              </TrackingNavLink>
            }

            {window.locale === 'en' &&
              <TrackingNavLink tag={Link} to="/blog" className="navbar-side-item" onClick={() => handleClick()}>
                <FormattedMessage id="mobilesidebar.blog" defaultMessage="Blog" />
              </TrackingNavLink>
            }

            <NavLink
              href={`${supportUrl()}`}
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-side-item"
            >
              <FormattedMessage id="mobilesidebar.support" defaultMessage="Support" />
            </NavLink>

            {!authenticated &&
              <TrackingNavLink tag={Link} to="/about-us" className="navbar-side-item" onClick={() => handleClick()}>
                <FormattedMessage id="mobilesidebar.about" defaultMessage="About" />
              </TrackingNavLink>
            }

            {authenticated &&
              <TrackingNavLink tag={Link} to="/community" className="navbar-side-item" onClick={() => handleClick()}>
                <FormattedMessage id="mobilesidebar.community" defaultMessage="Community" />
              </TrackingNavLink>
            }


          </div>
        }
      </div>
    </div>
  );
};

MobileSideBar.propTypes = {
  authenticated: PropTypes.bool,
  email: PropTypes.string,
  liveDisplayTypes: PropTypes.shape({}),
  siteBrand: PropTypes.string,
  siteTheme: PropTypes.string,
  pathname: PropTypes.string,
};

MobileSideBar.defaultProps = {
  authenticated: false,
  email: '',
  liveDisplayTypes: {},
  siteBrand: 'fanatical',
  siteTheme: '',
  pathname: '',
};

const mapStateToProps = ({
  auth: { authenticated, email },
  liveDisplayTypes,
  siteBrand,
  siteTheme,
  router: { location: { pathname } },
}) => ({
  authenticated,
  email,
  liveDisplayTypes,
  siteBrand,
  siteTheme,
  pathname,
});

export default connect(mapStateToProps)(MobileSideBar);
