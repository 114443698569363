import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import persistStore from 'redux-persist/lib/persistStore';
import createTransform from 'redux-persist/lib/createTransform';

import store from '../../redux/store';
import { initialiseSplitTests } from '../../redux/ducks/split-test';
import { addVoucher } from '../../redux/ducks/voucher';
import { hydrateConsent, openCookieCollapsible } from '../../redux/ducks/cookie';
import { getOne } from '../../Helpers/Cookie/CookieUtils';
import isBot from '../../Helpers/SEO/is-bot';

const PersistStore = ({ children }) => {
  const [loaded, setLoaded] = useState(isBot());

  useEffect(() => {
    const runAfterLoad = () => {
      const dispatch = store.dispatch;
      const state = store.getState();

      const validUntil = state?.voucher?.voucher?.valid_until || false;
      const discountType = state?.voucher?.voucher?.discount_type || false;
      const expiry = state?.voucher?.voucher?.expiry || false;
      const voucherCode = state?.voucher?.voucher?.code || false;
      const authenticated = state?.auth?.authenticated || false;
      const isAccountDiscount = discountType === 'Account';

      initialiseSplitTests();
      setLoaded(true);
      const today = new Date().toISOString();


      if (validUntil && validUntil < today) {
        // Tried to export REMOVE_VOUCHER string from voucher (Legacy commment from original code 5 years ago)
        // but it breaks combineReducers (Legacy commment from original code 5 years ago)
        dispatch({ type: 'frontend/voucher/REMOVE_VOUCHER' });
      } else if (voucherCode && ((expiry && expiry < today) || !expiry)) {
        if (!isAccountDiscount && !authenticated) {
          dispatch(addVoucher(voucherCode, isAccountDiscount));
        } else {
          dispatch({ type: 'frontend/voucher/REMOVE_VOUCHER' });
        }
      }

      // Pull cookie consent from cookies instead of persisting through local storage (Legacy commment from original code 5 years ago)
      const consentData = getOne('CookieConsent');
      if (consentData) {
        dispatch(hydrateConsent(consentData));
      } else {
        // If not found open cookie collapsible (Legacy commment from original code 5 years ago)
        dispatch(openCookieCollapsible());
      }

      dispatch({ type: 'frontend/auth/RESET_AUTH' });
    };

    if (!isBot()) {
      persistStore(
        store,
        {
          whitelist: [
            'auth',
            'anonymous',
            'ageGating',
            'steam',
            'voucher',
          ],
          keyPrefix: 'bs',
          transforms: [createTransform(
            liveState => liveState,
            persistedState =>
              // Remove legacy fields from saved data
              ({
                ...persistedState,
                library: undefined,
                steamLibrary: undefined,
                user_traits: undefined,
              }),
            { whitelist: ['auth'] },
          )],
        },
        runAfterLoad,
      );
    }
  }, []);


  if (!loaded) return false;
  return React.Children.only(children);
};

PersistStore.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PersistStore;
