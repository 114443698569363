import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import { currencyCode, currencyName } from '../../Helpers/Geo/data';
import SpecialFormattedNumber from '../../containers/Utility/Price/SpecialFormattedNumber';
import CartCalcReselect from '../../reselects/cartcalc';
import ProductCover from '../Utility/ProductCover';
import CheckoutPrice from './CheckoutPrice';
import CheckoutWasPrice from './CheckoutWasPrice';
import CheckoutDiscount from './CheckoutDiscount';
import Drm from '../Utility/Drm';
import CountdownTimer from '../../components/Utility/CountdownTimer';
import FormatProductName from '../../Helpers/Format/FormatProductName';
import './CheckoutSideBar.scss';

function CheckoutSideBar({
  cartCalc: cart,
  voucher,
  nativeRates,
}) {
  const [sideBarOpen, toggleSideBar] = useState(true);

  const closeSideBar = () => {
    toggleSideBar(false);
  };

  const renderPlatforms = (drm) => {
    const validPlatforms = Object.keys(drm).filter(d => drm[d]);

    if (validPlatforms.length === 1) {
      return (
        <Drm drmType={validPlatforms[0]} iconSize="small" className="mr-3" />
      );
    }
    return false;
  };

  const renderOperatingSystems = (os) => {
    if ((os.windows) || (os.mac) || (os.linux)) {
      return (
        <div className="os-icons-container mr-3">
          {os.windows &&
            <FontAwesomeIcon icon={['fab', 'windows']} size="lg" />
          }
          {os.mac &&
            <FontAwesomeIcon icon={['fab', 'apple']} size="lg" />
          }
          {os.linux &&
            <FontAwesomeIcon icon={['fab', 'linux']} size="lg" />
          }
        </div>
      );
    }
    return false;
  };

  const renderEndingTimer = (item) => {
    const currentDiscount = item.current_discount;
    if (
      isEmpty(currentDiscount) ||
      (!currentDiscount.until && currentDiscount.percent === 0) ||
      currentDiscount.hide_timer
    ) return false;

    const now = Date.parse(new Date());
    const dealEndTime = Date.parse(currentDiscount.until);
    // Dont show label if discount time passes or if end time is greater than 48 hours away
    if (now > dealEndTime || (dealEndTime - now) / 1000 > 172800) return false;
    return (
      <CountdownTimer
        checkoutTimer
        showZero
        noLabel
        value={Date.parse(currentDiscount.until)}
      />
    );
  };

  const actualVoucher = get(voucher, 'voucher', {});

  const countryData = window.countryData || {};
  const nativeCurrencyCode = countryData['Native-Currency-Alphabetic-Code'];
  const nativeCurrencyName = countryData['Native-Currency-Name'];
  const isShownNative =
    nativeRates.from === currencyCode &&
    nativeRates.to === nativeCurrencyCode &&
    nativeCurrencyCode !== countryData['Currency-Alphabetic-Code'];

  if (isEmpty(cart.items)) return false;

  // if (sideBarOpen) {
  //   document.body.style.overflow = 'hidden';
  //   document.body.style.position = 'fixed';
  //   document.body.style.height = '100%';
  //   document.body.style.width = '100%';
  // }
  //
  // if (sideBarOpen === false) {
  //   document.body.style.overflow = '';
  //   document.body.style.position = '';
  //   document.body.style.height = '';
  //   document.body.style.width = '';
  // }

  /* eslint-disable react/style-prop-object */
  return (
    <div>
      <button aria-label="Close Side Bar" className={classNames({ 'd-block': sideBarOpen }, 'site-overlay')} onClick={closeSideBar} />

      <div className="site-side-bar">
        <div className={classNames('side-bar', { 'side-bar-reveal': sideBarOpen })} id="checkout-side-bar">
          <div className="side-bar-title">
            <FormattedMessage id="checkout-side-bar.title.welcome" defaultMessage="Welcome Back" />
          </div>
          <div className="side-bar-sub-title">
            <FormattedMessage id="checkout-side-bar.subtitle.saved" defaultMessage="We saved your cart from your last visit." />
          </div>

          <div className="side-bar-items-container">
            {uniq(cart.items, item => item._id).map(item =>
              <div key={item._id}>
                {item.updated &&
                  <div className="item-message">
                    <FormattedMessage
                      id="checkout-side-bar.item.message.price-updated"
                      defaultMessage="This product has changed price since it was added to your cart"
                    />
                  </div>
                }

                <div className="cart-item">
                  <div className="item-cover">
                    <Link to={`/${item.type}/${item.slug}`} onClick={closeSideBar}>
                      <ProductCover
                        thumbnail
                        cover={item.cover}
                        alt={`Product cover for ${FormatProductName(item.name, item.type)}`}
                        displayType={item.display_type}
                      />
                    </Link>
                  </div>
                  <div className="item-details-price-container">
                    <div className="item-name-icons-container">
                      <div className="item-name">
                        {FormatProductName(item.name, item.type)}
                      </div>
                      <div className="item-icons-container">
                        {item.drm && renderPlatforms(item.drm)}
                        {item.os && renderOperatingSystems(item.os)}
                        <div className="badges-container d-none">
                          {renderEndingTimer(item)}
                          {item.out && item.out.isStarDeal &&
                            <span className="badge badge-primary mr-1">
                              <FormattedMessage id="checkout-side-bar.item.badge.star-deal" defaultMessage="Star Deal" />
                            </span>
                          }
                          {item.pickAndMix &&
                            <Link to={`/pick-and-mix/${item.pickAndMix.slug}`}>
                              <span className="badge badge-primary mr-1">
                                {item.pickAndMix.quantity} for&#8200;
                                {item.pickAndMix.percentage ?
                                  `${item.pickAndMix.percentage}% off`
                                  :
                                  <SpecialFormattedNumber
                                    value={item.pickAndMix.promoPrice}
                                    style="currency"
                                    currency={currencyCode}
                                  />
                                }
                              </span>
                            </Link>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="pricing-container">
                      <CheckoutDiscount
                        item={item}
                        currencyCode={currencyCode}
                      />
                      <CheckoutWasPrice
                        item={item}
                        currencyCode={currencyCode}
                      />
                      <CheckoutPrice
                        item={item}
                        currencyCode={currencyCode}
                      />
                    </div>
                  </div>
                </div>
              </div>,
            )}
          </div>

          <div className="line-break" />

          <div className={classNames('side-bar-summary', { 'full-price': cart.fullprice === cart.total })}>
            <div className="summary-line-container checkout-full-price">
              <div className="summary-text">
                <FormattedMessage id="checkout-side-bar.summary.full-price" defaultMessage="Full price: " />
              </div>
              <div className="summary-number">
                <SpecialFormattedNumber
                  value={cart.fullprice}
                  style="currency"
                  currency={currencyCode}
                />
              </div>
            </div>

            {cart.saving > 0 &&
              <div className="summary-line-container checkout-saving">
                <div className="summary-text">
                  <FormattedMessage id="checkout-side-bar.summary.your-saving" defaultMessage="Your saving: " />
                </div>
                <div className="summary-number">
                  <SpecialFormattedNumber
                    value={cart.saving}
                    style="currency"
                    currency={currencyCode}
                  />
                </div>
              </div>
            }

            {cart.voucherSaving > 0 &&
              <div className="summary-line-container line-breaker checkout-subtotal">
                <div className="summary-text">
                  <FormattedMessage id="checkout-side-bar.summary.subtotal" defaultMessage="Subtotal: " />
                </div>
                <div className="summary-number">
                  <SpecialFormattedNumber
                    value={cart.grandTotal}
                    style="currency"
                    currency={currencyCode}
                  />
                </div>
              </div>
            }

            {!!actualVoucher.name &&
              <div className="summary-line-container checkout-voucher">
                <div className="summary-text">
                  {actualVoucher.name}
                </div>
                <div className="summary-number">
                  -
                  <SpecialFormattedNumber
                    value={cart.voucherSaving}
                    style="currency"
                    currency={currencyCode}
                  />
                </div>
              </div>
            }

            <div className="summary-line-container line-breaker checkout-total">
              <div className="summary-text">
                <FormattedMessage id="checkout-side-bar.summary.total" defaultMessage="Total: " />
              </div>
              <div className="summary-number">
                <SpecialFormattedNumber
                  value={cart.total}
                  style="currency"
                  currency={currencyCode}
                />
              </div>
            </div>

            <div className="summary-line-container paragraph-small">
              <div className="summary-text">
                {!isShownNative &&
                  <div>
                    <FormattedMessage
                      id="checkout-side-bar.summary.displayed-in"
                      defaultMessage="Prices displayed in"
                    />&nbsp;
                    {currencyName}
                  </div>
                }
                {isShownNative &&
                  <div>
                    <FormattedMessage
                      id="checkout-side-bar.summary.displayed-in-native"
                      defaultMessage="Prices displayed approximately in {currencyLabel}"
                      values={{
                        currencyLabel: nativeCurrencyName,
                      }}
                    />
                  </div>
                }
              </div>
            </div>

            {isShownNative &&
              <div className="summary-line-container mt-2">
                <div className="summary-text">
                  <FormattedMessage id="checkout-side-bar.summary.chargedIn" defaultMessage="You will be charged: {price}" values={{ price: '' }} />
                </div>
                <div className="summary-number">
                  <SpecialFormattedNumber
                    dontConvert
                    value={cart.total}
                    style="currency"
                    currency={currencyCode}
                  />
                </div>
              </div>
            }

            <div className="side-bar-buttons-container">
              <Link to="/cart" className="btn btn-primary" onClick={closeSideBar}>
                <FormattedMessage id="checkout-side-bar.summary.button.cart" defaultMessage="Go to cart" />
              </Link>
              <button className="btn btn-secondary" onClick={closeSideBar}>
                <FormattedMessage id="checkout-side-bar.summary.button.continue" defaultMessage="Continue shopping" />
              </button>
            </div>
          </div>

          <div className="side-bar-close-button-container">
            <button className="btn" onClick={closeSideBar}>
              <FontAwesomeIcon icon="times" size="lg" title="Close side bar" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

CheckoutSideBar.propTypes = {
  cartCalc: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  voucher: PropTypes.shape({}),
  nativeRates: PropTypes.shape({}).isRequired,
};

CheckoutSideBar.defaultProps = {
  cartCalc: {
    items: [],
  },
  voucher: {},
};

const mapStateToProps = state => ({
  cartCalc: CartCalcReselect(state),
  voucher: state.voucher,
  nativeRates: state.nativeRates,
});

export default connect(mapStateToProps)(CheckoutSideBar);
