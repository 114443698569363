const GeneralUtils = {};

GeneralUtils.getSaleData = () => {
  const saleStart = '2024-09-09T15:00:00.000Z';
  const saleEnd = '2024-09-23T15:00:00.000Z';
  let isSaleLive = false;
  if (Date.now() > Date.parse(saleStart) && Date.now() < Date.parse(saleEnd)) {
    isSaleLive = true;
  }

  const encoreStart = '2024-05-15T15:00:00.000Z';
  const encoreEnd = '2024-05-18T15:00:00.000Z';
  let isEncorelive = false;
  if (Date.now() > Date.parse(encoreStart) && Date.now() < Date.parse(encoreEnd)) {
    isEncorelive = true;
  }

  return { isSaleLive, isFlashSale: false, isEncorelive, saleName: 'BundleFest' };
};

GeneralUtils.getSnowplowId = () => {
  let domainUserId = '';
  if (window.snowplow) {
    window.snowplow(function snowcall() {
      const cf = this.cf;
      if (cf) {
        domainUserId = cf.getDomainUserId();
      }
    });

    return domainUserId;
  }

  return domainUserId;
};

GeneralUtils.setReferalCode = (code) => {
  if (code) {
    localStorage.setItem('refer-a-friend', code);
  }
};

GeneralUtils.getReferalCode = () => localStorage.getItem('refer-a-friend') || null;


GeneralUtils.getShopperLocale = (code) => {
  switch (code) {
    case 'de':
      return 'de_DE';
    case 'es':
      return 'es_ES';
    case 'fr':
      return 'fr_FR';
    case 'hu':
      return 'hu_HU';
    case 'it':
      return 'it_IT';
    case 'ja':
      return 'ja_JP';
    case 'ko':
      return 'ko_KR';
    case 'nl':
      return 'nl_NL';
    case 'pl':
      return 'pl_PL';
    case 'pt':
      return 'pt_BR';
    case 'sv':
      return 'sv_SE';
    case 'tr':
      return 'tr_TR';
    case 'ru':
      return 'ru_RU';
    case 'zh-hant':
      return 'zh_TW';
    case 'zh-hans':
      return 'zh_CN';
    default:
      return 'en_US';
  }
};

GeneralUtils.newUser = () => {
  const hasLoggedIn = localStorage.getItem('hasLoggedIn') || false;

  return !hasLoggedIn;
};

export default GeneralUtils;
