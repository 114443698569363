import cloneDeep from 'lodash/cloneDeep';

import { RateLimitError } from '../../api';
import store from '../../redux/store';
import waitForSentry from '../../Helpers/Sentry/wait-for-sentry';

const sentryConfig = JSON.parse(window.atob(window.sentryConfigString || window.btoa('{}')));

sentryConfig.ignoreErrors = [
  // Network errors such as going offline or being blocked by a proxy
  'Failed to fetch',
  'The request timed out.',
  'Timeout',
  'Preflight response is not successful',
  'The network connection was lost.',
  'The Internet connection appears to be offline.',
  /NetworkError/i,
  /NetworkError when attempting to fetch resource/i,
  // Safari error in moengage service worker
  'bundleIdentifier cannot be empty.',
  // Service worker browser failure
  'SecurityError: Failed to register a ServiceWorker: ' +
  'No URL is associated with the caller\'s document.',
  'SecurityError: Failed to register a ServiceWorker: ' +
  'The script has an unsupported MIME type (\'text/html\').',
  'TypeError: Cannot set property \'install\' of undefined',
  'Cannot set property \'install\' of undefined',
  'UnhandledRejection "CustomEvent"',
  'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
  '"CustomEvent"',
  'TypeError cancelled',
  'cancelled',
  'TypeError: The operation couldn’t be completed. Software caused connection abort',
  'The operation couldn’t be completed. Software caused connection abort',
  /Software caused connection abort/i,
  /Unspecified error/i,
  /\$ is not defined/i,
  /Non-Error promise rejection captured with keys/i,
  /Invariant Violation/i,
  /too much recursion/i,
  /moz-extension/i,
  /chrome is not defined/i,
  /Indexed Database/, // iOS moengage issue
  /A mutation operation was attempted on a database that did not allow mutations/i, // FF moengage issue
  // Firefox extension DOM access error
  /can't access dead object/i,
  /Property description must be an object: undefined/i,
  /Cannot read property 'content' of undefined/i, //
  /e.data.indexOf is not a function/i, // Adyen checkout error
  /Failed to execute 'insertBefore' on 'Node'/, // Google translate error
  /Failed to load/i, // Sentry issue FRONTEND-RV1
  /Network request failed/i, // Sentry FRONTEND-2325
  /Non-Error promise rejection captured with value: false/i, // Sentry FRONTEND-235A
  /AlgoliaSearchJSONPScriptErrorError/i, // Sentry FRONTEND-5R
  /AlgoliaSearchUnparsableJSONError/i, // Sentry FRONTEND-21GX
  /AbortError/i, // Sentry FRONTEND-QMY
  /ResizeObserver loop limit exceeded/i, // Sentry FRONTEND-1028 - https://stackoverflow.com/a/50387233/1868365
  /ResizeObserver loop completed with undelivered notifications/i, // Sentry FRONTEND-E0P - https://stackoverflow.com/a/50387233/1868365
  /Object Not Found Matching Id/, // FRONTEND-4XA3 https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
  /can't redefine non-configurable property "userAgent"/, // Sentry FRONTEND-1NJ - I think this is GTM, can't see anything in our code that could be doing it
  /fanatical.com\/static\/js\/FrozenWaterScrape/i, // FRONTEND-5EPM Snowplow chunk seems to be being blocked by adblockers
  /ChunkLoadError/i, // FRONTEND-5EPM - Could never get to the bottom of it, possibly caused by flaky network connections
  /Promise\.resolve is not a function/i, // FRONTEND-5842
  /Loading CSS chunk/i, // FRONTEND-5B20
  /Load failed/i, // FRONTEND-5GXY
  /__tcfapi/i, // Audigent issue - FRONTEND-6VJ0 FRONTEND-6VJ4
  /ifameElement\.contentDocument\.addEventListener/i, // FRONTEND-6ZG5
  /429/, // FRONTEND-6TR1
  /530/, // FRONTEND-6TR1
  /createLauncher/, // Rokt FRONTEND-70NC
  /NotFoundError/, // FRONTEND-6ZJ5
  /Failed to execute 'removeChild' on 'Node'/i, // FRONTEND-6ZJ5
  /@webkit-masked-url/, // Safari extensions
  'V', // Google one tap script
  /Blocked a frame with origin "https:\/\/www\.fanatical\.com" from accessing/, // Usually GTM related
  '10450',
  /out of memory/,
  'Non-Error exception captured', // https://github.com/getsentry/sentry-javascript/issues/2546
  'Non-Error promise rejection captured', // https://github.com/getsentry/sentry-javascript/issues/2546
  'Event `Event` (type=error) captured as promise rejection', // FRONTEND-95ZH - Fandom identity engine
  'Event `Event` (type=error) captured as exception', // FRONTEND-A46A FRONTEND-A46D - Sentry Loader
  'Event `ErrorEvent` captured as exception with message `Script error.`', // FRONTEND-A46E - Sentry Loader
  'Object captured as promise rejection with keys: message, name, transporterStackTrace', // https://github.com/getsentry/sentry-javascript/issues/2546
  /pptr:\/\//, // Puppeteer FRONTEND-91X6
  /beacon\.wikia-services\.com/, // Fandom identity engine FRONTEND-9WJG
  'Self rate limit exceeded - ignore', // Separate rate limit error for when it's already thrown once
  /'This browser doesn't support the API's required to use the Firebase SDK'/i, // FRONTEND-A86F
  /disableWebkitEnterFullscreen/i, // Seems unreleated to us FRONTEND-A120
];

sentryConfig.denyUrls = [
  /userscript/,
  /moz-extension/,
  /AppData/,
  /moengage\.com\//i,
  /playbuzz\.com\//i,
  /newrelic\.com\//i,
  /ad\.gt\//i,
  /aufp\.io\//i,
  /translate\.baiducontent\.com/i,
  /datadome\.co\//i,
  /rokt\.com/i, // FRONTEND-7VN5
  /clarity\.ms/, // FRONTEND-7XMB
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Safari extensions
  /\/\/hidden\//i,
  /@webkit-masked-url/,
  /\/gsi\/client/, // Google one tap library
  /pay\.google\.com/, // Google Pay
  /script\.google\.com/, // Google Pay
  /embed\.tawk\.to/,
  /paypal\.com/,
  /about:blank/, // FRONTEND-7PRF
  /d\.mailer\.fanatical/, // FRONTEND-6ZG5 Cordial v2 tracker
  /pptr:\/\//, // Puppeteer FRONTEND-91X6
  /beacon\.wikia-services\.com/, // Fandom identity engine FRONTEND-9WJG
];

sentryConfig.tags = sentryConfig.tags || {};

sentryConfig.tags.page_locale = window.locale;
sentryConfig.tags.country = window.countryData ? window.countryData['Country-Name'] : undefined;
sentryConfig.tags.currency = window.countryData ? window.countryData['Currency-Name'] : undefined;

sentryConfig.beforeSend = (e, hint) => {
  try {
    const isNonErrorException = e.exception.values[0].value.startsWith('Non-Error exception captured') || hint.originalException.message.startsWith('Non-Error exception captured');

    if (isNonErrorException) {
      // We want to ignore those kind of errors (FRONTEND-6ZFY)
      return null;
    }

    if (hint.originalException && hint.originalException instanceof RateLimitError) {
      if (hint.originalException.ignore) {
        return null;
      }

      e.fingerprint = [`ratelimit-${hint.originalException.path}`];
    }

    const state = store.getState();

    sentryConfig.tags.siteTheme = state.siteTheme;
    sentryConfig.tags.siteBrand = state.siteBrand;

    sentryConfig.tags.gtmVersion = window.gtmVersion || null;

    /**
     * Shouldn't have to do this, but it doesn't send tags for some reason
     */
    const isGoogleTranslated = !!document.querySelector('html').className.match('translated');
    sentryConfig.tags.isGoogleTranslated = isGoogleTranslated;
    sentryConfig.tags.isPolyfill = !!window.isPolyfill;

    e.tags = cloneDeep(sentryConfig.tags);
  } catch (ex) {
    return e;
  }
  return e;
};

sentryConfig.sendDefaultPii = true;

const integrations = [
  ['extraErrorDataIntegration'],
  ['httpClientIntegration', {
    failedRequestTargets: [/www\.fanatical\.com/],
    failedRequestStatusCodes: [[429, 559]],
  }],
  ['contextLinesIntegration'],
];

// Called when Sentry loader has been initialised
window.sentryOnLoad = () => {
  window.Sentry.init(cloneDeep(sentryConfig));

  integrations.forEach(([name, config]) => {
    window.Sentry.lazyLoadIntegration(name)
      .then(
        (integration) => {
          window.Sentry.addIntegration(integration(config));
        },
      )
      .catch(e => console.warn(`Sentry integration ${name} failed to load`, e)); // eslint-disable-line no-console
  });
};

// Load the loader
import('./sentry-script');

setTimeout(() => {
  // Force load the full script after a delay
  waitForSentry(Sentry => Sentry.forceLoad());
}, 2000);
