import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import store from '../../redux/store';

export default function CategoriesLink({ setMegaMenuTab, megaMenuTab }) {
  const [delayHandler, setDelayHandler] = useState(null);

  const clickButton = () => {
    setMegaMenuTab(megaMenuTab === '' ? 'categories' : '');
    setTimeout(() => {
      const navLink = document.getElementById('categories-gamesunder5');
      if (navLink) navLink.focus();
    }, 200);

    store.dispatch({
      type: 'frontend/click/CLICK_INTERACTION',
      payload: { section: 'SecondaryNavLinks', value: '/categories', context: 'Categories' },
    });
  };

  const handleMouseEnter = () => {
    setDelayHandler(setTimeout(() => {
      setMegaMenuTab('categories');
    }, 200));
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
    setMegaMenuTab('');
  };

  return (
    <div className="store-link-button">
      <Link
        aria-expanded={megaMenuTab === 'categories'}
        aria-controls="MegaMenu_categories"
        aria-haspopup="true"
        aria-label="categories, dropdown"
        to="/categories"
        className="secondary-nav-link"
        onClick={() => { clickButton(); }}
        onMouseEnter={() => { handleMouseEnter(); }}
        onMouseLeave={() => { handleMouseLeave(); }}
      >
        <FormattedMessage id="categories-link.name" defaultMessage="Categories" />
        <div className="link-arrow" />
      </Link>
    </div>
  );
}

CategoriesLink.propTypes = {
  setMegaMenuTab: PropTypes.func,
  megaMenuTab: PropTypes.string,
};

CategoriesLink.defaultProps = {
  setMegaMenuTab: () => {},
  megaMenuTab: '',
};
